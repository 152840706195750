<template>
  <el-dialog
    v-model="show"
    width="405px"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-source-auth-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-source-auth-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <div v-loading="loading">
      <iframe
        v-if="props.thirdPartyOpenAuthTypeData?.extra?.link_url && show"
        ref="iframeRef"
        :src="props.thirdPartyOpenAuthTypeData?.extra?.link_url"
        class="elv-account-source-auth-content-iframe"
      />
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { ThirdPartyOpenAuthType } from '#/AccountsTypes'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  thirdPartyOpenAuthTypeData: {
    type: Object as PropType<ThirdPartyOpenAuthType>,
    required: true
  }
})

const show = defineModel('show', { type: Boolean, required: true })

const loading = ref(true)
const iframeRef = ref<HTMLElement | null>(null)

const hideLoading = () => {
  loading.value = false
}

const initIframe = () => {
  nextTick(() => {
    const iframe = unref(iframeRef)
    if (!iframe) return
    const _frame = iframe as any
    if (_frame.attachEvent) {
      _frame.attachEvent('onload', () => {
        hideLoading()
      })
    } else {
      iframe.onload = () => {
        hideLoading()
      }
    }
  })
}

watch(
  [() => show.value, () => props.thirdPartyOpenAuthTypeData],
  () => {
    if (show.value && props.thirdPartyOpenAuthTypeData?.extra?.link_url) {
      loading.value = true
      initIframe()
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.el-dialog.elv-account-source-auth-dialog {
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 0;
    min-height: 720px;

    > div {
      width: 100%;
      min-height: 720px;
      height: fit-content;
    }

    .elv-account-source-auth-content-iframe {
      width: 100%;
      min-height: 720px;
      box-sizing: border-box;
      border: none;
    }
  }
}
</style>

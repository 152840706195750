<template>
  <el-dialog
    v-model="showAccountDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-dialog-header__title">
        {{ dialogTitle }}
      </h4>
    </template>

    <PlatformList
      v-if="showPlatform"
      :platform-data="platformData"
      @onSelectedPlatform="onSelectedPlatform"
      @onClickContinue="onClickContinue"
    />

    <template v-else>
      <div v-if="!isEmpty(platformData)" class="elv-account-dialog-content__platform">
        <img :src="platformData.bigLogo" :alt="platformData.name" />
      </div>

      <AccountForm
        ref="accountFormRef"
        :model="props.model"
        :platform-data="platformData"
        :loading="fromLoading"
        :show-dialog="showAccountDialog"
        :current-data="props.currentData"
        :counterparty-list="counterpartyList"
        :auxiliary-code-option-data="auxiliaryCodeOptionData"
        @resetList="resetList"
      />
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import AccountsApi from '@/api/AccountsApi'
import AccountForm from './AccountForm.vue'
import { PlatformType } from '#/EntityTypes'
import PlatformList from './PlatformList.vue'
import { uniqBy, isEmpty, cloneDeep } from 'lodash-es'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const fromLoading = ref(false)
const showPlatform = ref(true)
const accountFormRef = ref()
const platformData: any = ref({})
const counterpartyList: any = ref([])
const auxiliaryCodeOptionData: any = ref({
  list: [],
  total: 0
})
const showAccountDialog = ref(false)

const emit = defineEmits(['resetDetail'])

const dialogTitle = computed(() => {
  if (props.model === 'edit') return t('button.editAccount')
  if (isEmpty(platformData.value) || showPlatform.value) return t('button.addAccount')
  return platformData.value.type === 'CHAIN'
    ? t('title.addChainWallet', { chain: platformData.value.name })
    : t('title.addPlatformAccount', {
        platform: platformData.value.name
      })
})

// eslint-disable-next-line no-unused-vars
const entityId = computed(() => {
  return route.params?.entityId as string
})

const onSelectedPlatform = (platform: PlatformType) => {
  platformData.value = cloneDeep(platform)
  const { platformProviderMapList } = platformData.value
  platformProviderMapList.forEach((item: any) => {
    if (item.isDefault) item.provider.name = `${item.provider.name} (Default)`
  })
  platformData.value.platformProviderMapList = platformProviderMapList
}

const onClickContinue = () => {
  showPlatform.value = !showPlatform.value
}

const onCheckAccountDialog = () => {
  showAccountDialog.value = !showAccountDialog.value
}

const onCloseDialog = () => {
  platformData.value = {}
  if (!showPlatform.value) {
    accountFormRef.value?.onResetFields()
    showPlatform.value = false
  }
}

const resetList = () => {
  onCheckAccountDialog()
  emit('resetDetail')
}

const getAuxiliaryCodeList = async () => {
  try {
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, { page: 1, limit: 100 })
    const list = [...cloneDeep(auxiliaryCodeOptionData.value.list), ...data.list].filter(
      (item: any) => item.type !== 'ENTITY_ACCOUNT'
    )
    auxiliaryCodeOptionData.value.total = data.total
    auxiliaryCodeOptionData.value.list = uniqBy(list, 'auxiliaryTypeId')
  } catch (error) {
    console.log(error)
  }
}

defineExpose({ onCheckAccountDialog, showAccountDialog })

watch(
  () => showAccountDialog.value,
  async () => {
    counterpartyList.value = []
    if (showAccountDialog.value) {
      if (props.model === 'edit' && !isEmpty(props.currentData)) {
        showPlatform.value = false
        platformData.value = props.currentData?.platform
      }
      if (props.model === 'add') {
        showPlatform.value = true
      }
      fromLoading.value = true
      const counterpartyOptions = new Promise((resolve, reject) => {
        AccountsApi.getCounterpartyList(entityId.value, {
          limit: 20,
          page: 1
        })
          .then((response) => {
            counterpartyList.value = response.data.list.filter((item: any) => item.type !== 'FEE')
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })
      try {
        await Promise.all([counterpartyOptions, getAuxiliaryCodeList()])
      } catch (error) {
        console.log(error)
      } finally {
        fromLoading.value = false
      }
    }
  }
)
</script>

<style lang="scss">
.elv-account-dialog {
  width: 620px;
  min-height: 481px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-account-dialog-content-scrollbar__wrap {
      width: 100%;
      box-sizing: border-box;
      padding: 0px 24px;
    }

    p {
      margin: 0;
    }

    .el-form-item__content {
      width: 100%;
    }

    .elv-account-dialog-content__platform {
      margin: 0 auto 16px;
      display: flex;
      width: 140px;
      padding: 0px 15px 0px 17px;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .el-textarea {
      width: 572px;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-select:not(.elv-accounts-data-short-select, .elv-accounts-data-shorter-select) {
      width: 572px;
    }

    .elv-accounts-data-secret-textarea {
      .el-textarea__inner {
        min-height: 44px !important;
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 14px;
        color: #0e0f11;
        line-height: 18px;
        padding-top: 12px;
        padding-bottom: 10px;
      }
    }

    .elv-accounts-data-checkbox.el-checkbox .el-checkbox__label {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0;
    }

    .elv-accounts-data-item-checkbox {
      display: flex;
      flex-direction: column;
      width: 100%;

      .el-checkbox {
        min-height: 28px;
        height: fit-content;
        margin-right: 0;
        max-width: 572px;
        display: flex;
        align-items: flex-start;

        .el-checkbox__input {
          width: 16px;
          height: 16px;
          transform: translateY(2px);
        }

        .el-checkbox__label {
          white-space: pre-wrap;
          line-height: normal;
          max-width: calc(100% - 24px);
        }
      }
    }

    .elv-accounts-data-short-select {
      .el-input,
      .el-select__wrapper {
        width: 282px;
      }
    }

    .elv-accounts-data-shorter-select {
      .el-input,
      .el-select__wrapper {
        width: 178px;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      .elv-accounts-data-select-content-csv__tips {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 8px;
        width: 100%;
      }

      .elv-accounts-data-select-integration-icon {
        width: 20px;
        height: 20px;
        border-radius: 25px;
      }

      &.elv-form-item__fileType {
        margin-bottom: 8px;
      }

      &.elv-accounts-form-item-date {
        .el-form-item__content {
          width: 387px;
          margin-left: 8px;
          justify-content: space-between;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          margin-left: 4px;
        }
      }
    }

    .elv-accounts-data-select-period-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .elv-accounts-data-select-content-period__tips {
      margin-top: 8px;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      .el-select__selected-item {
        .el-tag {
          padding: 0 16px;
          height: 24px;
          border-radius: 40px;
          border: 1px solid #d0d4d9;
          box-sizing: border-box;
          color: #636b75;
          background-color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-accounts-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-accounts-data-select-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;

      &.period {
        flex-direction: column;
        align-items: flex-start !important;
      }

      .el-form-item {
        margin-bottom: 0px;
      }

      .elv-accounts-date-select {
        box-sizing: border-box;
        height: 44px;
        width: 282px;
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        &.is-short {
          width: 178px;
        }

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        &:hover,
        &.is-focus {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 12px;
          fill: #838d95;
          transition: transform 0.3s;
        }

        .elv-accounts-date-time-value {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .elv-accounts-date-scope {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-accounts-date-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: 237px;

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;
    }

    .el-date-picker__header {
      margin: 0;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}

.elv-accounts-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-accounts-data-select-integration-info {
      color: #aaafb6;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Barlow';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      padding: 6px 8px;
      align-items: center;
      gap: 10px;
      border-radius: 14px;
      background: #f9fafb;
      margin-left: 8px;
    }

    .elv-accounts-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>

<template>
  <el-dialog
    v-model="showDeleteAccountDialog"
    width="480px"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-delete-tip-account-dialog"
  >
    <template #header="{ titleId }">
      <div class="elv-delete-tip-account-dialog-header-title">
        <h4 :id="titleId">
          {{ isLoading ? `${t('title.deleteAccountDialogTitle')}` : titleText }}
        </h4>
      </div>
    </template>
    <div v-if="isLoading" class="elv-account-delete-dialog-checking">
      <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
      {{ t('title.checkingDeleteAccountStatus') }}
    </div>
    <div v-else class="elv-delete-tip-account-dialog-content-showstatus">
      <div v-if="isCanDelete" class="elv-delete-tip-account-dialog-content">
        <p>{{ t('message.deleteAccountRecoverTipInfo') }}</p>
        <p class="center-tip">{{ t('message.deleteAccountSourceTipInfo') }}</p>
        <p class="center-tip">{{ t('message.deleteAccountJournalTipInfo') }}</p>
        <p class="input-tip">{{ t('message.pleaseEnterNameOfAccount') }}</p>
        <el-input v-model="curAccountName" :placeholder="t('message.pleaseEnterNameOfAccount')" style="width: 432px" />
      </div>
      <div v-else class="elv-delete-tip-account-dialog-unavailable">
        <p>{{ t('message.deleteAccountUnavailableInfo') }}</p>
      </div>
    </div>
    <template #footer>
      <div class="elv-delete-tip-account-dialog-footer-content">
        <div v-if="isLoading" class="elv-delete-tip-account-dialog-footer-btns">
          <el-button type="primary" round @click="onClickCancelHandler">{{ t('button.ok') }}</el-button>
        </div>
        <div v-else class="elv-delete-tip-account-dialog-footer-btns">
          <el-button
            v-if="isCanDelete"
            type="info"
            round
            class="elv-Account-delete-dialog-footer__cancel"
            @click="onClickCancelHandler"
            >{{ t('button.cancel') }}</el-button
          >
          <el-button
            type="primary"
            round
            :loading="deletingLoading"
            class="elv-Account-delete-dialog-footer__delete"
            @click="onClickConfirmHandler"
            >{{ isCanDelete ? t('button.delete') : t('button.ok') }}</el-button
          >
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'

const props = defineProps({
  delCountName: {
    type: String,
    default: ''
  },
  isCanDelete: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onDelConfirmEvent', 'onDelCancelEvent'])

const { t } = useI18n()
const curAccountName = ref('')
const isLoading = ref(false)
const showDeleteAccountDialog = ref(false)
const deletingLoading = ref(false)

const titleText = computed(() => {
  return props.isCanDelete
    ? t('title.deleteCustomTitle', { name: props.delCountName })
    : t('title.thisAccountCanNotBeDeleted')
})

const changeLoadingStatus = (loadingStatus: boolean) => {
  isLoading.value = loadingStatus
}

const closeDeleteDialog = () => {
  showDeleteAccountDialog.value = !showDeleteAccountDialog.value
  curAccountName.value = ''
  deletingLoading.value = false
}

const changeDelDialogStatus = (isOpenDialog: boolean = false) => {
  changeLoadingStatus(isOpenDialog)
  closeDeleteDialog()
}

const onClickConfirmHandler = () => {
  if (isLoading.value || deletingLoading.value) return
  if (props.isCanDelete && curAccountName.value !== props.delCountName) {
    ElMessage.warning(t('message.enterAccountName'))
    return
  }
  if (props.isCanDelete) {
    deletingLoading.value = true
  }
  emit('onDelConfirmEvent')
}

const onClickCancelHandler = () => {
  if (deletingLoading.value) return
  emit('onDelCancelEvent')
}

defineExpose({
  closeDeleteDialog,
  changeDelDialogStatus,
  changeLoadingStatus
})
</script>

<style lang="scss">
.elv-delete-tip-account-dialog {
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .elv-delete-tip-account-dialog-header-title {
    min-height: 60px;
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-account-delete-dialog-checking {
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    img {
      display: block;
      width: 32px;
      height: 32px;
      animation: loading-rotate 2s linear infinite;
      margin-bottom: 16px;
    }
  }

  .elv-delete-tip-account-dialog-content-showstatus {
    min-height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;

    .elv-delete-tip-account-dialog-content {
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px;

      p {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: #1e2024;

        &.center-tip {
          padding-left: 6px;

          &::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #636b75;
            margin-right: 8px;
            position: relative;
            top: -6px;
          }
        }

        &:nth-of-type(2) {
          color: #df2200;

          &::before {
            background-color: #df2200;
          }
        }

        &:first-child {
          margin-bottom: 15px;
        }

        &.input-tip {
          margin: 16px 0px 8px;
          font-size: 13px;
          font-weight: 600;
          color: #636b75;
        }
      }

      .el-input__wrapper {
        width: 432px;
      }
    }

    .elv-delete-tip-account-dialog-unavailable {
      width: 390px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1e2024;
      font-size: 14px;
      font-family: 'Plus Jakarta Sans';
    }
  }

  .elv-delete-tip-account-dialog-footer-content {
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;

    .elv-delete-tip-account-dialog-footer-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;

      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 113px;
        background: #1753eb;
        border-radius: 22px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #fff;
        border: 0px;
      }

      .elv-Account-delete-dialog-footer__cancel {
        background: #edf0f3;
        color: #636b75;
      }

      .elv-Account-delete-dialog-footer__delete {
        margin-left: 20px;
      }
    }
  }
}
</style>
